<template>
  <v-card class="mx-auto" :loading="loading" :disabled="loading">
    <v-card-title>
      <v-row>
        <v-col cols="12" xs="12" md="8">
          <BtnCircle
            tLabel="Atrás"
            tPosition="bottom"
            icon="mdi-arrow-left"
            color="grey darken-3"
            size="x-small"
            :to="{ name: page_route }"
          />
          <span class="text-h6 ml-1" v-text="$route.meta.title" />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
          <v-tooltip v-if="login.permissions.users.update" top>
            <template v-slot:activator="{ on }">
              <v-btn
                class="ml-1"
                v-on="on"
                fab
                x-small
                color="warning"
                @click="passUpdateDialog"
              >
                <v-icon v-text="'mdi-lock'" />
              </v-btn>
            </template>
            <span v-text="'Cambiar contraseña'" />
          </v-tooltip>
          <BtnCircle
            tLabel="Editar"
            tPosition="bottom"
            icon="mdi-pencil"
            color="info"
            size="x-small"
            :to="{ name: `${page_route}.update`, params: { id: id } }"
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text v-if="data">
      <v-row>
        <v-col cols="12">
          <v-card class="mx-auto">
            <v-card-title>
              <h2 class="text-caption" v-text="'GENERAL'" />
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row dense>
                <v-col cols="12" xs="12" md="3">
                  <ViewData label="Rol" :value="data.rol.rol" />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData label="Nombre de usuario" :value="data.email" />
                </v-col>
                <v-col cols="12" xs="12" md="3" />
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Correo electrónico"
                    :value="data.email2 ? data.email2 : 'NO REGISTRADO'"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Tope generacion carta"
                    :value="numberFormat(data.amount_letter)"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Número de revisión"
                    :value="
                      data.letters_review == 0
                        ? 'N/A'
                        : data.letters_review == 9
                        ? 'TODAS'
                        : data.letters_review
                    "
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Nombre en firma"
                    :value="data.name_signature"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData label="Firma digital" value="" />
                  <img
                    v-if="data.signature"
                    height="70"
                    :src="url_documents + '/users/' + data.signature"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Verificación 2 pasos"
                    :value="data.mfa ? 'SI' : 'NO'"
                  />
                </v-col>
                <v-col
                  v-if="data.role_id == 6 || data.role_id == 8"
                  cols="12"
                  xs="12"
                  md="3"
                >
                  <ViewData
                    label="R. general Online"
                    :value="data.report_general ? 'SI' : 'NO'"
                  />
                </v-col>
                <v-col
                  v-if="data.role_id == 6 || data.role_id == 8"
                  cols="12"
                  xs="12"
                  md="3"
                >
                  <ViewData
                    label="R. restitución Online"
                    :value="data.report_restitution ? 'SI' : 'NO'"
                  />
                </v-col>
                <v-col
                  v-if="data.role_id == 6 || data.role_id == 8"
                  cols="12"
                  xs="12"
                  md="3"
                >
                  <ViewData
                    label="Columna tipo asegurado Online"
                    :value="data.report_insured_type ? 'SI' : 'NO'"
                  />
                </v-col>
                <v-col v-if="data.role_id == 8" cols="12" xs="12" md="3">
                  <ViewData
                    label="Búsqueda por contratante Online"
                    :value="data.find_for_contractor ? 'SI' : 'NO'"
                  />
                </v-col>
                <v-col v-if="data.role_id == 13" cols="12" xs="12" md="3">
                  <ViewData
                    label="Formato INFO"
                    :value="
                      data.pharmacy_format
                        ? data.pharmacy_format.pharmacy_format
                        : null
                    "
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" v-if="data.role_id !== 12 && data.role_id !== 8">
          <v-card class="mx-auto">
            <v-card-title>
              <h2 class="text-caption" v-text="'PROVEEDORES'" />
            </v-card-title>
            <v-divider />
            <v-card-text>
              <span
                v-for="(provider, i) in data.providers_list"
                :key="i"
                v-text="provider.provider.name + ' | '"
              >
              </span>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card class="mx-auto">
            <v-card-title>
              <h2 class="text-caption" v-text="'ASEGURADORAS'" />
            </v-card-title>
            <v-divider />
            <v-card-text>
              <span v-for="(insurance, i) in data.insurances_list" :key="i">
                {{ insurance.insurance.name }}
                <span v-if="data.role_id === 12">
                  (
                  <span
                    v-for="(provider_element, j) in data
                      .insurance_providers_list[insurance.insurance.id]"
                    :key="j"
                    v-text="provider_element.provider.name + ' - '"
                  />
                  )
                </span>
                {{ " | " }}
              </span>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" v-if="data.role_id == 6 || data.role_id == 8">
          <v-card class="mx-auto">
            <v-card-title>
              <h2
                class="text-caption"
                v-text="
                  'CONTRATANTE' +
                  (!data.find_for_contractor
                    ? 'S | CONCENTRADORES'
                    : ' | ' + data.find_contractor.name)
                "
              />
            </v-card-title>
            <v-divider />
            <v-card-text v-if="!data.find_for_contractor">
              <span
                v-for="(
                  user_contractor_brand, i
                ) in data.user_contractor_brands"
                :key="i"
              >
                {{ user_contractor_brand.contractor_brand.contractor_brand }}
                {{ " | " }}
              </span>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" v-if="data.role_id == 16">
          <v-card class="mx-auto">
            <v-card-title>
              <h2 class="text-caption" v-text="'OFICINAS DE CONTRATANTE'" />
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row dense>
                <v-col cols="12" xs="12" md="12">
                  <span
                    v-for="(item, i) in data.txt_user_office_addresses"
                    :key="i"
                  >
                    {{ item }}
                    {{ " | " }}
                  </span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card class="mx-auto">
            <v-card-title>
              <h2 class="text-caption" v-text="'PERMISOS'" />
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row dense>
                <v-col cols="12">
                  <v-simple-table dense>
                    <template v-slot:default>
                      <tbody>
                        <tr
                          v-for="(permission, i) in data.permissions"
                          :key="i"
                          class="my-0 py-0"
                        >
                          <td class="my-0 py-0">
                            <v-icon small v-text="permission.icon" />
                            {{ permission.interface_name }}
                          </td>
                          <td>
                            <v-icon
                              small
                              v-text="
                                permission.read ? 'mdi-check' : 'mdi-close'
                              "
                              :color="permission.read ? 'success' : ''"
                            />
                            {{ " Ver" }}
                          </td>
                          <td>
                            <v-icon
                              small
                              v-text="
                                permission.create ? 'mdi-check' : 'mdi-close'
                              "
                              :color="permission.create ? 'success' : ''"
                            />
                            {{ " Crear" }}
                          </td>
                          <td>
                            <v-icon
                              small
                              v-text="
                                permission.update ? 'mdi-check' : 'mdi-close'
                              "
                              :color="permission.update ? 'success' : ''"
                            />
                            {{ " Editar" }}
                          </td>
                          <td>
                            <v-icon
                              small
                              v-text="
                                permission.delete ? 'mdi-check' : 'mdi-close'
                              "
                              :color="permission.delete ? 'success' : ''"
                            />
                            {{ " Eliminar" }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card class="mx-auto">
            <v-card-title>
              <h2 class="text-caption" v-text="'REGISTRO'" />
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row>
                <v-col cols="12" xs="12" md="6">
                  <ViewData
                    label="Creación"
                    :value="!loading && data.created_by.email"
                    :subvalue="data.created_at"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="6">
                  <ViewData
                    label="U. actualización"
                    :value="!loading && data.updated_by.email"
                    :subvalue="data.updated_at"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row dense v-if="login.permissions.users.delete">
        <v-col cols="12" xs="6" md="6">
          <BtnCircle
            tLabel="Desactivar"
            tPosition="top"
            icon="mdi-delete"
            color="error"
            size="x-small"
            :click="HandleDesactivate"
            :loading="loading"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-dialog v-model="pass_update_dialog" persistent max-width="600">
      <v-card tile :disabled="loading" :loading="loading">
        <v-toolbar dark>
          <v-toolbar-title v-text="'Actualizar'" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn dark text @click="passUpdateDialogClose">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="pass_update_dialog">
          <v-container>
            <v-form v-on:submit.prevent ref="pass_update_form" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Contraseña*"
                    v-model="pass_update.password"
                    :rules="rules.password"
                    :type="show_password ? 'text' : 'password'"
                    :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="show_password = !show_password"
                    autocomplete="new-password"
                  />
                </v-col>
                <v-col cols="12" class="text-right">
                  <v-btn color="warning" @click="passUpdate">
                    <v-icon v-text="'mdi-lock'" left />
                    Aceptar
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  msgConfirm,
  msgAlert,
  rules,
  URL_DOCUMENTS,
} from "../../control";
import { show, destroy } from "../../requests/pageRequest";
import BtnCircle from "../../components/BtnCircle.vue";
import ImgAvatar from "../../components/ImgAvatar.vue";
import ViewData from "../../components/ViewData.vue";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    BtnCircle,
    ImgAvatar,
    ViewData,
    FaqDlg,
  },
  data() {
    return {
      page_route: "user",
      page_title: "Usuario",
      url_documents: URL_DOCUMENTS,
      id: this.$attrs.id,
      login: this.$store.getters.getLogin,
      loading: true,
      data: null,
      pass_update: {},
      pass_update_dialog: false,
      show_password: false,
      rules: rules(),
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    getData() {
      this.loading = true;

      show("general/users", this.login.token, this.id).then((response) => {
        this.data = response;
        this.loading = false;
      });
    },
    HandleDesactivate() {
      this.$swal
        .fire(msgConfirm("Confirma la desactivación del registro?"))
        .then((response) => {
          if (response.isConfirmed) {
            this.loading = true;

            destroy(
              "general/users",
              this.login.token,
              this.id,
              this.login.id
            ).then((response) => {
              this.$swal.fire(
                msgAlert(
                  response.success ? "success" : "error",
                  response.message
                )
              );

              response.success
                ? this.$router.push({ name: this.page_route })
                : console.log(response.message);
            });
          }
        });
    },
    getPermissions(permisson) {
      return (
        "Ver: " +
        (permisson.read ? "Si." : "No.") +
        " Crear: " +
        (permisson.create ? "Si." : "No.") +
        " Editar: " +
        (permisson.update ? "Si." : "No.") +
        " Eliminar: " +
        (permisson.delete ? "Si." : "No.")
      );
    },
    passUpdateDialog() {
      this.pass_update = {
        id: parseInt(this.id),
        password: "",
        updated_by_id: this.login.id,
      };

      this.pass_update_dialog = true;
    },
    passUpdateDialogClose() {
      this.pass_update_dialog = false;
      this.show_password = false;
      this.$refs.pass_update_form.resetValidation();
    },
    passUpdate() {
      if (this.$refs.pass_update_form.validate()) {
        this.$swal
          .fire(msgConfirm("¿Confirma actualizar la contraseña?"))
          .then((response) => {
            if (response.isConfirmed) {
              this.loading = true;

              Axios.post(
                URL_API + "/general/users/passupdate",
                this.pass_update,
                headersToken(this.login.token)
              ).then((response) => {
                response = response.data;

                this.$swal.fire(
                  msgAlert(
                    response.success ? "success" : "error",
                    response.message
                  )
                );

                if (response.success) {
                  this.passUpdateDialogClose();
                  this.getData();
                } else {
                  console.log(response.message);
                }
              });
            }
          });
      }
    },
    numberFormat(v) {
      return Number(parseFloat(v).toFixed(2)).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    this.getData();
  },
};
</script>